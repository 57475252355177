import React from 'react'
import {Oval} from 'react-loader-spinner'
import styled from 'styled-components'
import Model from '../Model/UserModel'
import { Body,PackTogether,LeftTabs,RightTab,EstateBanner,WriteUps,ContainR,Center, Texts} from '../Reuse_ui/Css'
import {Btn, ButtonIcon} from '../Reuse_ui/Widget'
import {RiAddLine,RiEdit2Fill,RiSortDesc, RiPaypalFill} from 'react-icons/ri'
import { Get_session, Message,ToUpper,checkService } from '../Reuse_ui/Logic'
import { isNumber, redirect2, Submit} from '../actions_api'
import Usermodel from '../Model/Familymodel'
import Studentmodel from '../Model/Studentmodel'
import { useNavigate, useParams} from 'react-router-dom';
import { useState } from 'react'
import { useEffect } from 'react'
import RewardCar from './RewardCar'
import ScanModel from '../Model/ScanModel'
import Button from 'react-bootstrap/Button'; 
import ScanMode from '../Model/ScanMode'









const ScansTable = ({list,acs,typ}) => {





  const [showModel, setShowModel] = useState("close");
  const [showMode, setShowMode] = useState("close");
  const [UpdateText, setUpdateText] =  useState("Choose model")
  const [node, setNode] = useState("");
  const [UserType, setUserType] = useState("");
  const [shareImage, setShareImage] = useState("");
  const [progress, setProgress] = useState(0);
  const [fileN, setfileN] = useState("");
  const [companyWrite, setcompanyWrite] = useState("");
  const [index, setIndex] = useState(0)
  const [url, setUrl] = useState("");
  const [show, setshow] = useState(true);
  const navigate = useNavigate();
 
  



  const handle = (e) => {
    const file = e.target.files[0];
    setProgress(0);
    if(file === '' || file === undefined){
        alert('The file is  a  ${typeof image}');
        return;
    }
    if(file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg"  || file.type === "image/webp"){
        setShareImage(file);
         setfileN(file.name);
          setUrl("");
     }
}






const OpenModel = (e,da) => {
  setNode(da);
  switch(showModel){
    case "open":
          setShowModel("close");
          break;
    case "close":
          setShowModel("open");
          break;
    default:
          setShowModel("close");
          break;
  }
}


const OpenMode = (e,da) => {
 
  switch(showMode){
    case "open":
          setShowMode("close");
          break;
    case "close":
          setShowMode("open");
          break;
    default:
          setShowMode("close");
          break;
  }
}



function CheckBox(){
  if(show)
     setshow(false);
  else
     setshow(true);
}



function check(){
  if(Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage)[0].User.service === ToUpper(process.env.REACT_APP_CARPARK_NODES))
            setUpdateText("Send");
}



async function SubmitForm(){
  if(isNumber(companyWrite) && companyWrite.length > 0){
          await Submit(fileN+Math.floor(Date.now()/1000)+".png",shareImage,companyWrite,window.location.href,show,null);

   }else
        Message('Info','Invalid value','info')
}





useEffect(() => {

    if(Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage)){
       check();
         console.log("Continue")
           setUserType(Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage)[0].User.service)
             setUrl(Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage)[0].User.bannerUrl)
               redirect2(Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage),0,navigate);
      }

},[]);
 
   
    return ( 
          <Body w={'100%'} h={"auto"}  mh={"220vh"} style={{background:"#f5f5f5"}}>
            <br/><br/>
            <PackTogether>

                  <LeftTabs>
                    {
                    list[0] ? 
                      list.map((v,i) => <RewardCar k={i} v={v}  OpenModel={OpenModel}  icon={<RiSortDesc  size={25}  color="#FF9900"/>} />)
                    : 
                      <Center>
                        <Oval color={"#2D3476"}  height={40} width={40}/>
                      </Center>
                    }
                   </LeftTabs>

                   {showModel === "open" ? <ScanModel list={node} fun={OpenModel} ui={0} typ={typ}/> : null}
                   {showMode === "open" ? <ScanMode list={[fileN+Math.floor(Date.now()/1000)+".png",shareImage,show,window.location.href]} fun={OpenMode} /> : null}
                

                  <RightTab h={"110vh"}  mh={"130vh"} mt={"20px"}> 

                    <EstateBanner mt={"-150px"}>
                        <input type="file"   name="image" id="file" style={{display: "none"}}  onChange={handle}  accept="image/png, image/gif, image/jpeg, image/jpg"/>
                          {shareImage && <img style={{marginTop:"0px"}} src={URL.createObjectURL(shareImage) }/>}
                           {url.length > 0 && <img src={checkService(UserType,url)}/>}
                 
                          <br/>
                        <p><label  htmlFor="file">{"Choose  banner + "}</label></p>
                        <br/>

                    </EstateBanner>
                    
                        {UserType === process.env.REACT_APP_CARPARK_NODES.toUpperCase() 
                            ?
                            <PriceSection>
                            <h5>
                              Carpark Payment Package
                            </h5>
                           <Payment>
                               <div>
                                    <input  type="radio"  name='life' checked={show ?  true : false}  onClick={(e) => CheckBox()} />
                                    <label for="month"> &nbsp; Charge on exit</label>
                               </div>
                               <div>
                                    <input  type="radio" name='month' checked={!show ?  true : false} onClick={(e) => CheckBox()} />
                                    <label for="life"> &nbsp; Charge by time interval</label>
                               </div> 
                            </Payment>
    
                                {!show ? 
                                    <MonthCard>
                                        <WriteUps placeholder='Price' value={companyWrite}   onChange={(e) => setcompanyWrite(e.target.value)} /> 
                                    </MonthCard> 
                                  : 
                                   <MonthCard>
                                       <WriteUps placeholder='Price' value={companyWrite}   onChange={(e) => setcompanyWrite(e.target.value)} /> 
                                   </MonthCard>
                                   }
                            
                          </PriceSection>
                            :
                          null
                         }  
                         {
                          Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage) != undefined || Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage) != null
                          ?
                          <div style={{textAlign:"center", width:"100%"}}>
                            <Texts>Business Email: {Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage)[0].User.company_email}</Texts>
                            <Texts>Business Phone: {Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage)[0].User.company_phone}</Texts>
                            <Texts>Signup Date: {Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage)[0].User.signUpDate}</Texts>
                            <Texts>Business Name: {Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage)[0].User.company_name}</Texts>
                            <Texts> {UpdateText == "Send" ? "Fee" : "Loyalty Point:"} {Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage)[0].User.Payment.MP}</Texts>
                           
                             {UpdateText == "Send" ? 
                              <Texts> Mode: {Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage)[0].User.Payment.Flag == 1 ? "Charge on Exit." : "Time Interval."}</Texts>
                             :
                              null
                             }
                         
                          </div>
                           :
                           console.log("n/a")
                           } 
                       
                        <div onClick={(e) =>{ UpdateText == "Send" ? SubmitForm() : setShowMode("open") }}>
                           <Button>{UpdateText}</Button>
                          <br/><br/>
                        </div> 
                </RightTab>
            </PackTogether>
        </Body>
        
     )
}



const PriceSection = styled.div`
height:100vh;
width:50%;
top: 0;
bottom:0;
h5{
padding:10px;
}
@media(max-width:968px){
width:100%;
height:300px;
position:relative;
padding-top:20px;
padding-bottom:50px;
}
::-webkit-scrollbar{
display: none;
}
h5{
text-align:center;
}
`;


const Payment = styled.div`
display:flex;
flex-flow:column;
flex-wrap:wrap;
border-bottom:1px solid #e6e6e6;
padding-top:20px;
padding-bottom:20px;
font-size:9pt;
text-align:left;
div{
display:flex;
padding:5px;
align-items:center;
text-align:center;
}
@media(max-width:980px){
div{
width:50%; 
margin: 0 auto;
}
}
`;


const MonthCard = styled.div`
width:100%;
height:72%;
@media(max-width:980px){
width:100%; 
display:flex;
justify-content:center;
align-items:center;
margin: 0 auto;
}
`;


export default ScansTable