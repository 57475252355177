import React,{ useEffect, useState } from 'react'
import styled from 'styled-components'
import {Body} from '../Reuse_ui/Css'
import {Btn} from '../Reuse_ui/Css';
import { useParams } from 'react-router-dom'
import {Input, Type, USERDATA, Boxed} from './Login'
import { GetLinkReset, ResetLink, Tr } from '../actions_api'
import {Oval} from 'react-loader-spinner'
import { Message } from '../Reuse_ui/Logic';
import { ButtonIcon } from '../Reuse_ui/Widget';
import {Center} from '../Admins/NotifyTable'

const Mreseter = () => {

 let  {e} = useParams();
 const [password, setPassword] = useState("");
 const [Conpassword, setConfirmPassword] = useState("");
 const [showloader, setshowloader] = useState(false);



 const OpenRequest = async (n) => {
  if(Same(Conpassword,password)){
      setshowloader(true);
      let res = await GetLinkReset(n);
      
      if(res){
        setshowloader(false);
         Message("Info",res.data.message,"info")
      }

   }else
      Message("Info","Password not match","info")
 }

 const request = async (n) => {
  let res = await ResetLink(n);
   if(res.data.message.includes("Invalid Link"))
      Message("Info",res.data.message,"info")
 }


 function Same(a,b){
  return a === b ? true : false;
 }

 useEffect(() => {
    request(e); 
 },[])


  return (
    <Body  w={"100%"} h={"100vh"}   mh={"100vh"} bck_img={null}  c={null}>
         <Frame>
            <Type style={{marginLeft:"5px"}}>Password reset </Type>
             <USERDATA>
                  <input onChange={(e) => setPassword(e.target.value)}  value={password} placeholder='Password' type="password"/>
                  <input onChange={(e) => setConfirmPassword(e.target.value)}  value={Conpassword} placeholder='Confirm password' type="password"/>
                  <br/>
                  <div onClick={(s) =>{ Tr(Conpassword) && Tr(password) ? OpenRequest([password,e]) : Message("Form validation","Pls fill out all fields !","warning")}}>
                      {showloader ? <Btn><Oval color={"#fff"}  height={20} width={20}/></Btn> : <ButtonIcon   text={'Reset'}  icon={null}  size={"100%"}/>}
                  </div>
              </USERDATA>
         </Frame>
    </Body>
  )
}



const Frame = styled.div`
width:40%;
margin-left:auto;
margin-right:auto;
margin-top:5%;
padding-right:15px;
padding-bottom:35px;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
@media(max-width:900px){
width:90%;
margin-top:35%;
}
`;




export default Mreseter